import { IPlusIconButtonProps } from '../../types/components/buttons';
import { margin } from '@mui/system';
import { IBlock } from '../../pages/ProcessingPage/processingPreprocess/processingPreprocess.models';

export const AddPreprocessButton = (props: IPlusIconButtonProps) => {
  const { handlePlusButtonClick, title, error, marginZero, blocks, data, convert } = props;

  const isDisabled = blocks?.every((block: IBlock) => block.singleActionBody?.isCompleted === true);
  const isDisabledAtFirst = blocks?.some(
    (block) =>
      block.action === 'Manage Columns' &&
      Array.isArray(block.singleActionBody.columns) && // Check correct property
      data &&
      Object.keys(data) &&
      Object.keys(data).length === block.singleActionBody.columns.length &&
      Object.keys(data).every((key) => block.singleActionBody.columns.includes(key)) &&
      ['drop', 'rename'].includes(block.singleActionBody.subAction) // Ensure subAction is 'drop' or 'rename'
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: convert ? 'flex-start' : 'flex-end',
        width: '100%',
        paddingRight: '40px',
        marginTop: '20px',
        marginBottom: marginZero ? '0' : '80px'
      }}
    >
      <button
        style={{
          display: 'flex',
          fontFamily: 'Circular Std Book',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: convert ? '#fff' : isDisabledAtFirst || !isDisabled ? 'rgb(238, 239, 241)' : '#202090',
          color: convert
            ? isDisabledAtFirst || !isDisabled
              ? '#000'
              : '#5AA32E'
            : isDisabledAtFirst || !isDisabled
            ? '#202090'
            : '#fff',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          cursor: 'pointer',
          border: 'none'
        }}
        onClick={handlePlusButtonClick}
        disabled={isDisabledAtFirst || !isDisabled}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="16px" height="16px">
          <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z" />
        </svg>
      </button>
      <div style={{ marginLeft: convert ? '0' : '8px' }}>
        <h3
          style={{
            color: convert
              ? isDisabledAtFirst || !isDisabled
                ? '#000'
                : '#5AA32E'
              : isDisabledAtFirst || !isDisabled
              ? '#000'
              : '#202090',
            fontFamily: 'Circular Std Book',
            fontSize: convert ? '14px' : '16px',
            fontWeight: '400',
            margin: 0
          }}
        >
          {title}
        </h3>
        {error && <p style={{ color: 'red', fontSize: '12px', margin: 0 }}>Max count is 1</p>}
      </div>
    </div>
  );
};
