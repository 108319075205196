import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import {
  actionsOptionsParentStyles,
  bodyStyles,
  childActionRadioStyles,
  columnsAutocompleteLiSpecificStyles,
  columnsAutocompleteLiStyles,
  columnsAutocompletePlaceholderStyles,
  columnsAutocompleteStyles,
  columnsDropdownWrapperStyles,
  customValueInputStyles,
  formControlStyles,
  parentActionFirstOptionStyles,
  parentActionLabelStyles,
  parentActionRadioStyles,
  replacementOptionStyles
} from './handleMissingValuesBody.styles';
import { IHandleMissingValuesBody } from './handleMissingValuesBody.models';
import { getFilteredDropdownOptions } from '../../processingPreprocessInnerBlock.constants';

const HandleMissingValuesBody: React.FC<IHandleMissingValuesBody> = ({
  columnsThatHaveOnlyNaValues,
  dropdownOptions,
  block,
  setBlocks,
  updateSingleActionBody,
  blocks
}) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    block.singleActionBody.columns?.length > 0 ? block.singleActionBody.columns : []
  );
  const [selectedColumnsTypes, setSelectedColumnsTypes] = useState<string[]>(
    block.singleActionBody.selectedColumnsTypes ?? []
  );
  const [onlyNumericalTypes, setOnlyNumericalTypes] = useState<boolean>(false);
  const [onlyCategoricalTypes, setOnlyCategoricalTypes] = useState<boolean>(false);
  const [bothTypes, setBothTypes] = useState<boolean>(false);
  const [selectedParentAction, setSelectedParentAction] = useState<string>(
    block.singleActionBody.subAction?.length > 0 ? block.singleActionBody.subAction : ''
  );
  const [replacementOption, setReplacementOption] = useState<string>(
    block.singleActionBody.subActionValue?.length > 0 ? block.singleActionBody.subActionValue : ''
  );
  const [customValue, setCustomValue] = useState<any>(
    onlyNumericalTypes
      ? block.singleActionBody.outputColumnName && !isNaN(Number(block.singleActionBody.outputColumnName))
        ? Number(block.singleActionBody.outputColumnName)
        : ''
      : block.singleActionBody.outputColumnName ?? ''
  );
  const [isSelectedAllNAColumns, setIsSelectedAllNAColumns] = useState<boolean>(false);
  const [allNAElements, setAllNAElements] = useState<[]>([]);
  const [allNAElementsAsText, setAllNAElementsAsText] = useState<string>('');

  const allFieldsAreFilled =
    selectedColumns.length > 0 &&
    (selectedParentAction === 'drop' ||
      (selectedParentAction !== 'drop' &&
        selectedParentAction.length > 0 &&
        ((replacementOption.length > 0 && replacementOption !== 'custom') ||
          (replacementOption === 'custom' && customValue.length > 0))));

  const handleColumnsChange = (_: any, newValue: Array<{ type: string; label: string }>) => {
    setSelectedColumns(newValue.map((option) => option.label));
    setSelectedColumnsTypes(newValue.map((option) => option.type));
  };

  const handleParentActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedParentAction(event.target.value as 'replace' | 'drop');
  };

  const handleReplacementOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplacementOption(event.target.value);
  };

  useEffect(() => {
    const newActionBody = {
      columns: selectedColumns,
      subAction: selectedParentAction,
      subActionValue:
        replacementOption === '0' && onlyNumericalTypes ? parseFloat(replacementOption) : replacementOption,
      outputColumnName: onlyNumericalTypes ? parseFloat(customValue) : customValue,
      selectedColumnsTypes: selectedColumnsTypes,
      isCompleted: allFieldsAreFilled
    };
    if (replacementOption !== 'custom') {
      setCustomValue('');
    }
    if (selectedParentAction === 'drop') {
      setReplacementOption('');
    }
    const updatedBlocks = updateSingleActionBody(blocks, block.id, newActionBody);
    setBlocks(updatedBlocks);
    setIsSelectedAllNAColumns(columnsThatHaveOnlyNaValues.some((item: any) => selectedColumns.includes(item)));
    setAllNAElements(columnsThatHaveOnlyNaValues.filter((item: any) => selectedColumns.includes(item)));
    setAllNAElementsAsText((prev) =>
      selectedColumns.filter((col) => columnsThatHaveOnlyNaValues.includes(col)).join(', ')
    );

    if (selectedColumns.length === 0) {
      setSelectedParentAction('');
      setReplacementOption('');
      setCustomValue('');
    }
  }, [
    selectedColumns,
    selectedParentAction,
    replacementOption,
    customValue,
    onlyNumericalTypes,
    onlyCategoricalTypes,
    bothTypes
  ]);

  useEffect(() => {
    setOnlyNumericalTypes(!selectedColumnsTypes.includes('object'));
    setOnlyCategoricalTypes(selectedColumnsTypes.every((item) => item === 'object'));
    setBothTypes(selectedColumnsTypes.includes('object') && selectedColumnsTypes.some((item) => item !== 'object'));
  }, [selectedColumnsTypes]);

  useEffect(() => {
    setSelectedParentAction('');
    setReplacementOption('');
    setCustomValue('');
  }, [selectedColumns]);

  return (
    <div style={bodyStyles}>
      <div style={columnsDropdownWrapperStyles}>
        <Autocomplete
          multiple
          value={dropdownOptions.filter((option: { id: number; label: string }) =>
            selectedColumns.includes(option.label)
          )}
          options={getFilteredDropdownOptions(dropdownOptions, blocks, block)} // Filter out selected options
          onChange={handleColumnsChange}
          sx={columnsAutocompleteStyles}
          renderOption={(props, option) => (
            <li {...props} style={columnsAutocompleteLiStyles}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="select columns"
              label=""
              InputProps={{
                ...params.InputProps,
                style: columnsAutocompleteLiStyles
              }}
              sx={columnsAutocompletePlaceholderStyles}
            />
          )}
          componentsProps={{
            clearIndicator: {
              onClick: () => setSelectedColumns([])
            }
          }}
        />
        {allNAElements.length > 0 && (
          <span style={columnsAutocompleteLiSpecificStyles}>
            The {allNAElementsAsText} column{allNAElements.length > 1 ? 's' : ''} contain
            {allNAElements.length > 1 ? '' : 's'} only NA values, so the 'drop missing rows' and 'replace with mean' or
            'replace with median' functionalities are not available."
          </span>
        )}
        {bothTypes && (
          <span style={columnsAutocompleteLiSpecificStyles}>
            The 'replace with' functionality is not available for both selected numeric and categorical columns.
          </span>
        )}
      </div>
      {selectedColumns.length > 0 && (
        <div>
          <div>
            <RadioGroup
              value={selectedParentAction}
              onChange={handleParentActionChange}
              style={actionsOptionsParentStyles}
            >
              {!bothTypes && (
                <FormControlLabel
                  style={parentActionFirstOptionStyles}
                  value="replace"
                  control={<Radio sx={parentActionRadioStyles} />}
                  label={
                    <span style={{ ...parentActionLabelStyles, ...parentActionFirstOptionStyles }}>replace with</span>
                  }
                />
              )}
              {!isSelectedAllNAColumns && (
                <FormControlLabel
                  value="drop"
                  control={<Radio sx={parentActionRadioStyles} />}
                  label={<span style={parentActionLabelStyles}>drop missing rows</span>}
                />
              )}
            </RadioGroup>
          </div>
          {selectedParentAction === 'replace' && (
            <div>
              <RadioGroup
                value={replacementOption}
                onChange={handleReplacementOptionChange}
                style={replacementOptionStyles}
              >
                {onlyCategoricalTypes && (
                  <FormControlLabel
                    sx={formControlStyles}
                    value="mode"
                    control={<Radio sx={childActionRadioStyles} />}
                    label={<span style={{ ...parentActionLabelStyles, ...parentActionFirstOptionStyles }}>mode</span>}
                  />
                )}
                {onlyNumericalTypes && (
                  <>
                    {allNAElements.length === 0 && (
                      <FormControlLabel
                        sx={formControlStyles}
                        value="mean"
                        control={<Radio sx={childActionRadioStyles} />}
                        label={
                          <span style={{ ...parentActionLabelStyles, ...parentActionFirstOptionStyles }}>mean</span>
                        }
                      />
                    )}
                  </>
                )}
                {onlyNumericalTypes && (
                  <>
                    {allNAElements.length === 0 && (
                      <FormControlLabel
                        sx={formControlStyles}
                        value="median"
                        control={<Radio sx={childActionRadioStyles} />}
                        label={
                          <span style={{ ...parentActionLabelStyles, ...parentActionFirstOptionStyles }}>median</span>
                        }
                      />
                    )}
                  </>
                )}
                {onlyNumericalTypes && (
                  <FormControlLabel
                    sx={formControlStyles}
                    value="0"
                    control={<Radio sx={childActionRadioStyles} />}
                    label={<span style={{ ...parentActionLabelStyles, ...parentActionFirstOptionStyles }}>0</span>}
                  />
                )}
                {(bothTypes || onlyNumericalTypes || onlyCategoricalTypes) && (
                  <FormControlLabel
                    sx={formControlStyles}
                    value="custom"
                    control={<Radio sx={childActionRadioStyles} />}
                    label={<span style={parentActionLabelStyles}>custom value</span>}
                  />
                )}
                {replacementOption === 'custom' && (
                  <input
                    value={customValue}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (!onlyNumericalTypes || /^-?\d*\.?\d*$/.test(inputValue)) {
                        setCustomValue(inputValue.slice(0, 40));
                      }
                    }}
                    style={customValueInputStyles}
                  />
                )}
              </RadioGroup>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HandleMissingValuesBody;
