import API from '../../../manager/API';
import resourcesMiddleware from '../resources/middleware';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setUploadStatusData } = slice.actions;

const uploadStatus = (params: any, url: any, resourcesUrl: any) => async () => {
  try {
    const response = await API.uploadStatus.uploadStatus(params, url);
    dispatch(setUploadStatusData(response.data));
    if (response.data.queue_state === 'SUCCESS') {
      dispatch(resourcesMiddleware.getResources(resourcesUrl));
    }
    // toast.success('Upload successful!');
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    return { status: error.response.status, data: error.response.data };
  }
};

export default {
  uploadStatus
};
